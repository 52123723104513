import { useEffect, useState } from 'react'
import debounce from 'lodash-es/debounce'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const Tailwind = resolveConfig(tailwindConfig)

export const useBreakpoint = () => {
	const breakpointLG = parseInt(Tailwind.theme.screens.lg)
	const breakpointMD = parseInt(Tailwind.theme.screens.md)
	const breakpointSM = parseInt(Tailwind.theme.screens.sm)
	const breakpointXS = parseInt(Tailwind.theme.screens.xs)
	const breakpointMaster = parseInt(Tailwind.theme.screens['3xl'])

	const [isMobile, setIsMobile] = useState(undefined)
	const [isTablet, setIsTablet] = useState(undefined)
	const [isMaster, setIsMaster] = useState(undefined)
	const [isScreenLg, setIsScreenLg] = useState(undefined)
	const [isScreenMd, setIsScreenMd] = useState(undefined)
	const [isScreenSm, setIsScreenSm] = useState(undefined)
	const [isScreenXs, setIsScreenXs] = useState(undefined)

	const setBreakpoints = () => {
		if (typeof window !== 'undefined') {
			setIsMobile(window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches)
			setIsTablet(window.matchMedia(`(max-width: ${breakpointLG - 1}px)`).matches)
			setIsMaster(window.matchMedia(`(min-width: ${breakpointMaster}px)`).matches)
			setIsScreenLg(window.matchMedia(`(max-width: ${breakpointLG - 1}px)`).matches && window.matchMedia(`(min-width: ${breakpointMD}px)`).matches) //lg
			setIsScreenMd(window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches && window.matchMedia(`(min-width: ${breakpointSM}px)`).matches) //md
			setIsScreenSm(window.matchMedia(`(max-width: ${breakpointSM - 1}px)`).matches && window.matchMedia(`(min-width: ${breakpointXS}px)`).matches) //sm
			setIsScreenXs(window.matchMedia(`(max-width: ${breakpointXS - 1}px)`).matches) //xs && xs-
		}
	}
	const onWindowResize = debounce(setBreakpoints, 250)
	useEffect(() => {
		if (typeof window !== 'undefined') {
			setBreakpoints()
			window.addEventListener('resize', onWindowResize)
			return () => {
				window.removeEventListener('resize', onWindowResize)
			}
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	return {
		isMaster,
		isMobile,
		isTablet,
		isScreenLg,
		isScreenMd,
		isScreenSm,
		isScreenXs,
	}
}
