const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  mode: 'jit',
  purge: {
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    options: {
      safelist: ['transform'],
    },
  },
  theme: {
    screens: {
      '3xs': '321px',
      xxs: '375px',
      xs: '480px',
      ...defaultTheme.screens,
      macbook: '1400px',
      '2xl': '1440px',
      '1500px': '1500px',
      '3xl': '1727px',
      fhd: '1900px',
      '2k': '2200px',
    },
    extend: {
      colors: {
        radler: '#006E42',
        green: '#006E42',
        helles: '#004580',
        blue: '#004580',
        lightGray: '#F3F3F3',
        gray: '#444444',
        darkGray: '#989898',
        gelb: '#F7D303',
        gloriole: '#EAE7E0',
        brown: '#8C734B',
        heading: '#444444',
        accordionGrey: '#eeeeee',
        lightBrown: '#A38E6A',
      },
      spacing: {
        'bottom-offset': '52px',
        '90px': '90px',
        18: '70px',
        22: '88px',
        26: '102px',
        30: '114px',
      },
      maxWidth: {
        master: '1000px',
      },
      scale: {
        40: '0.4',
        45: '0.45',
        65: '0.65',
      },
      fontFamily: {
        lobster: ['Lobster', 'sans-serif'],
        roboto: ['Roboto', 'sans-serif'],
        Blackriver: ['Blackriver-Bold', 'sans-serif'],
        DIN: ['DIN Condensed Bold', 'sans-serif'],
      },
      fontSize: {
        '11px': ['11px', '15px'],
        '13px': ['13px', '16px'],
        '15px': ['15px', '26px'],
        '18px': ['18px', '29px'],
        '22px': ['22px', '26px'],
        '25px': ['25px', '32px'],
        '29px': ['29px', '57px'],
        '30px': ['30px', '36px'],
        '32px': ['32px', '35px'],
        '40px': ['40px', '57px'],
        '50px': ['50px', '70px'],
        '69px': ['69px', '57px'],
        '75px': ['75px', '75px'],
        '75pxv2': ['75px', '80px'],
        '80px': ['80px', '80px'],
        90: ['90px', '90px'],
        '100px': ['100px', '57px'],
        '130px': ['130px', '57px'],
        '160px': ['160px', '57px'],
        '180px': ['180px', '57px'],
        '240px': ['240px', '57px'],
      },
      letterSpacing: {
        '2%': '0.02em',
        0.25: '0/5px',
      },
    },
  },
  plugins: [],
}
